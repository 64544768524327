import axios from 'axios';
export const refreshheaderToken = (token) => {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        axios.defaults.headers.common['Authorization'] = null;
    }
};
const servInstance = process.env.REACT_APP_BASE_URL;

const api = {
    get: (URL, queryParams, type = {}) => axios.get(servInstance + URL, queryParams, type).then((res) => res),
    post: (URL, reqData = {}) => axios.post(servInstance + URL, reqData).then((res) => res),

    put: (URL, reqData = {}) => axios.put(servInstance + URL, reqData).then((res) => res),

    delete: (URL, reqData = {}) => axios.delete(servInstance + URL, reqData).then((res) => res),
};

const fetchJSON = (url, options = {}) => {
    return fetch(servInstance + url, options)
        .then((response) => {
            console.log(response);
            if (!response.status === 200) {
                console.log('failed');
                throw response.json();
            }
            console.log('failed');
            return response.json();
        })
        .then((json) => {
            console.log('failed');
            return json;
        })
        .catch((error) => {
            console.log(error);
            throw error;
        });
};

export { fetchJSON, api };
