import React, { Component } from 'react';
import Routes from './routes/Routes';

import { Toaster } from 'react-hot-toast';
import './assets/css/style.css';
import './assets/scss/theme.scss';

class App extends Component {
    render() {
        return (
            <>
                <Toaster position="top-right" gutter={15} />

                <Routes />
            </>
        );
    }
}

export default App;
