// @flow

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();

    if (!user) {
        return false;
    }
    // const decoded = jwtDecode(user.token);

    if (!user.token) {
        console.warn('access token expired');
        return false;
    } else {
        return true;
    }
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    const user = localStorage.getItem('user');

    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};
const getLoggedInUserPermissions = () => {
    const permission = localStorage.getItem('permission');

    return permission ? JSON.parse(permission)  : null;
};


const getActiveUser = () => {
    const user = localStorage.getItem('active_user');

    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

export { isUserAuthenticated, getLoggedInUser, getActiveUser , getLoggedInUserPermissions};
